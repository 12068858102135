.about-container {
   display: flex;
   flex-direction: row;
   align-items: center;
   margin: 40px;
 }
 
 .about-slider-container {
   flex: 1; /* Allows the slider container to take up available space */
 }
 
 .paragraph-container {
   flex: 1; /* Allows the paragraph container to take up available space */
 }
 
 .about-slider-container img {
   width: 100%;
   height: 400px; /* Fixed height */
   object-fit: cover; /* Ensures the image covers the area */
 }
 
 /* Responsive styles */
 @media (max-width: 768px) {
   .about-container {
     flex-direction: column; /* Stack items vertically on small screens */
     margin: 20px; /* Reduce margin for smaller screens */
   }
 
   .about-slider-container img {
     height: 300px; /* Reduce height for smaller screens */
   }
   
   .paragraph-container p {
     padding: 10px; /* Reduce padding for smaller screens */
     text-align: left; /* Align text to the left for better readability on small screens */
   }
 }
 