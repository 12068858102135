header {
  position: relative;
  color: white;
  text-align: center;
}

nav {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 10px 0;
  z-index: 2; /* Ensure the navigation is on top */
}
.dropdown-toggle{
  color: white !important;
}
nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 15px;
}


@media (max-width: 768px) {
  .hide-on-mobile {
    display: none; /* Hide on screens smaller than 768px */
  }
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1em;
}

.slider-container {
  position: relative;
}

.header-slider .slick-slide {
  position: relative;
}

.header-slider img {
  width: 100vw;
  height: 500px; /* Adjust height as needed */
  object-fit: cover;
}

.slide-content {
  position: absolute;
  bottom: 150px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.slide-content h1 {
  font-size: 3em;
  margin: 0;
}

.slide-content p {
  font-size: 1.5em;
  margin: 10px 0 0;
}

.slick-dots {
  bottom: 10px;
}

.slick-dots li button:before {
  color: white;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px; /* Adjust height as needed */
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  z-index: 1; /* Ensure the overlay is below the navigation */
}


.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 60px; /* Adjust size as needed */
  height: 60px; /* Adjust size as needed */
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white !important;
}

/* Position the left arrow */
.slick-prev {
  right: 60px !important; /* Adjust position as needed */
  left: auto !important;
}

/* Position the right arrow */
.slick-next {
  left: 30px !important; /* Adjust position as needed */
}

/* Styles for the arrow icons */
.slick-prev:before,
.slick-next:before {
  font-size: 60px !important; /* Adjust size as needed */
  color: grey !important;
}
