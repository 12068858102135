/* Artists.css */
.ma3rads-container {
    text-align: center;
    padding: 20px;
  }
  
  .ma3rads-title {
    font-size: 24px; /* Smaller title */
    margin-bottom: 40px;
  }
  
  .ma3rads-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Ensure 4 columns on larger screens */
    gap: 20px;
    justify-items: center;
  }
  
  .ma3rad-card {
    width: 100%; /* Ensure width is 100% to allow grid to control sizing */
    max-width: 300px; /* Max width to maintain 300px blocks */
    text-align: center;
  }
  
  .ma3rad-image-wrapper {
    position: relative;
    width: 100%;
    height: 300px; /* Fixed height for consistent sizing */
    border-radius: 8px;
    overflow: hidden;
  }
  
  .ma3rad-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Maintain aspect ratio, cover the entire area */
  }
  
  .ma3rad-name-overlay {
    position: absolute;
    bottom: 0;
    right: 0; /* Align text to the right */
    width: 100%; /* Ensures the overlay spans the full width */
    background: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 16px; /* Smaller font size */
    padding: 10px;
    text-align: right; /* Align text to the right */
    font-weight: bold;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 1200px) {
    .ma3rads-grid {
      grid-template-columns: repeat(3, 1fr); /* Adjust to 3 columns for smaller screens */
    }
  }
  
  @media (max-width: 900px) {
    .ma3rads-grid {
      grid-template-columns: repeat(2, 1fr); /* Adjust to 2 columns for smaller screens */
    }
  }
  
  @media (max-width: 600px) {
    .ma3rads-grid {
      grid-template-columns: 1fr; /* Adjust to 1 column for smallest screens */
    }
  
    .ma3rad-card {
      max-width: 100%; /* Full width on small screens */
    }
  
    .ma3rad-name-overlay {
      font-size: 14px; /* Adjust font size for small screens */
    }
  }
  .ma3rad-name {
    font-size: 16px;
    margin-top: 10px; /* Add space between image and name */
    text-align: center;
  }
  