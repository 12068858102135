.artists-carousel {
  padding: 20px 0;
}

.artist {
  text-align: center;
  padding: 10px;
}

.artist img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0 auto;
}

.artist p {
  margin-top: 10px;
  font-size: 1em;
  color: #333;
}

.slick-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.artists-carousel .slick-prev:before, .slick-next:before{
  line-height: 0 !important;
}



.artists-carousel .slick-prev {
  right: 60px !important; /* Adjust position as needed */
  left: auto !important;
}

/* Position the right arrow */
.artists-carousel .slick-next {
  left: 30px !important; /* Adjust position as needed */
}

.disp-6{
  margin: 25px;
  border-radius:8px;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;

}