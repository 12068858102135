.home {
    font-family: "Cairo", sans-serif;
  }
  
  .exhibitions {
    padding: 50px 20px;
    text-align: center;
  }
  
  .exhibitions h2 {
    margin-bottom: 30px;
    font-size: 2em;
  }
  
  .exhibitions-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
 }
  .exhibitions-grid-main{
    border-radius:8px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1);

  }


  .artist {
    padding: 20px 20px;
    text-align: center;
  }
  .artist h2 {
    margin-bottom: 30px;
    font-size: 2em;
  }


  .contact {
    padding: 20px 20px;
    text-align: center;
  }
  .contact h2 {
    margin-bottom: 30px;
    font-size: 2em;
  }

  .about {
    padding: 20px 20px;
    text-align: center;
  }
  .about h2 {
    margin-bottom: 30px;
    font-size: 2em;
  }

