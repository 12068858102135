.exhibition-card {
    overflow: hidden;
    margin: 10px;
    width: 300px;
    text-align: center;
  }
  
  .exhibition-card img {
    width: 280px;
    height: 280px;
    border-radius: 5px;
  }
  
  .exhibition-info {
    padding: 10px;
  }
  
  .exhibition-info h2 {
    margin: 10px 0;
    font-size: 1.2em;
  }
  
  .exhibition-info p {
    margin: 5px 0;
    color: #666;
  }
  