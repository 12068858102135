/* .contact-us {
  padding: 50px 20px;
} */

.contact-us h2 {
  margin-bottom: 20px;
  font-size: 2em;
  text-align: center;
}

.contact-info {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line */
  justify-content: space-around;
  margin-bottom: 30px;
}

.contact-item {
  text-align: center;
  width: 100%; /* Full width on small screens */
  max-width: 350px; /* Limit the width on larger screens */
  margin-bottom: 20px;
}

.contact-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.contact-item p {
  margin: 5px 0;
  color: #555;
}

/* Responsive styles */
@media (min-width: 600px) {
  .contact-item {
    width: 45%; /* Adjust to fit two items per row */
  }
}

@media (min-width: 900px) {
  .contact-item {
    width: 30%; /* Adjust to fit three items per row */
  }
}
