.buttons-group {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .filter-button {
    background: none;
    border: none;
    color: #007bff;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    outline: none;
  }
  
  .filter-button::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #007bff;
    transition: width 0.3s;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  
  .filter-button:hover::after {
    width: 100%;
  }
  